import { createSlice } from '@reduxjs/toolkit'
import { getEmployeeDetail, login } from './login.api'

export interface LoginState {
    loading: boolean
}
const initialState: LoginState = {
    loading: false
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(login.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getEmployeeDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getEmployeeDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getEmployeeDetail.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default loginSlice.reducer